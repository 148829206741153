<template>
  <div>
    <a-table rowKey="uid" :columns="columns" :data-source="dataItems" size="small" :pagination="false" :scroll="{ x: 1340 }">
      <template slot="department" slot-scope="value, item">
        <DepartmentSelect v-model="item.department" size="small" style="width: 100%" @change="handleChange" />
      </template>
      <template slot="ursa" slot-scope="value, item">
        <UrsaTable v-model="item.ursa_set" size="small" style="width: 100%" @change="handleChange" />
      </template>
      <template slot="airport" slot-scope="value, item">
        <AirportSelect v-model="item.airport" size="small" style="width: 100%" @change="handleChange" />
      </template>
      <template slot="destination_location" slot-scope="value, item">
        <DestinationLocationSelect
          v-model="item.destination_location"
          size="small"
          style="width: 100%"
          @change="handleChange"
        />
      </template>
      <template slot="country" slot-scope="value, item">
        <CountrySelect v-model="item.country" size="small" style="width: 100%" @change="handleChange" />
      </template>
      <template slot="residential" slot-scope="value, item">
        <ResidentialSelect v-model="item.residential" size="small" style="width: 100%" @change="handleChange" />
      </template>
      <template slot="postal_code" slot-scope="value, item">
        <PostalCodeSelect v-model="item.postal_code" size="small" style="width: 100%" @change="handleChange" />
      </template>
      <template slot="sensitive" slot-scope="value, item">
        <SensitiveSelect v-model="item.sensitive" size="small" style="width: 100%" @change="handleChange" />
      </template>
      <template slot="packing_code" slot-scope="value, item">
        <PackingCodeSelect v-model="item.packing_code" size="small" style="width: 100%" @change="handleChange" />
      </template>

      <template slot="target_crossing" slot-scope="value, item">
        <CrossingSelect
          v-model="item.target_crossing"
          :productionLine="productionLine"
          size="small"
          style="width: 100%"
          @change="handleChange"
        />
      </template>
      <template slot="action" slot-scope="value, item, index">
        <a-button-group size="small">
          <a-button type="danger" size="small" @click="removeItem(index)">移除</a-button>
        </a-button-group>
      </template>
      <template slot="footer">
        <a-button type="dashed" icon="plus" style="width: 100%" @click="addItem">新增条件</a-button>
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  components: {
    AirportSelect: () => import("@/components/AirportSelect"),
    DepartmentSelect: () => import("@/components/DepartmentSelect"),
    UrsaTable: () => import("@/components/UrsaTable"),
    DestinationLocationSelect: () => import("@/components/DestinationLocationSelect"),
    CrossingSelect: () => import("@/components/CrossingSelect"),
    CountrySelect: () => import("@/components/CountrySelect"),
    ResidentialSelect: () => import("@/components/ResidentialSelect"),
    PostalCodeSelect: () => import("@/components/PostalCodeSelect"),
    SensitiveSelect: () => import("@/components/SensitiveSelect"),
    PackingCodeSelect: () => import("@/components/PackingCodeSelect"),
  },
  props: ["value", "productionLine"],
  model: { prop: "value", event: "change" },
  data() {
    return {
      columns: [
        {
          title: "序号",
          width: 60,
          dataIndex: "index",
          fixed: "left",
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "Department",
          width: 120,
          dataIndex: "department",
          scopedSlots: { customRender: "department" },
        },
        {
          title: "Ursa",
          width: 120,
          dataIndex: "ursa",
          scopedSlots: { customRender: "ursa" },
        },
        {
          title: "Airport",
          width: 120,
          dataIndex: "airport",
          scopedSlots: { customRender: "airport" },
        },
        {
          title: "Destination Location",
          width: 120,
          dataIndex: "destination_location",
          scopedSlots: { customRender: "destination_location" },
        },
        {
          title: "Country",
          width: 120,
          dataIndex: "country",
          scopedSlots: { customRender: "country" },
        },
        {
          title: "Residential",
          width: 120,
          dataIndex: "residential",
          scopedSlots: { customRender: "residential" },
        },
        {
          title: "Postal Code",
          width: 120,
          dataIndex: "postal_code",
          scopedSlots: { customRender: "postal_code" },
        },
        {
          title: "Sensitive",
          width: 120,
          dataIndex: "sensitive",
          scopedSlots: { customRender: "sensitive" },
        },
        {
          title: "Packing Code",
          width: 120,
          dataIndex: "packing_code",
          scopedSlots: { customRender: "packing_code" },
        },
        {
          title: "目标道口",
          width: 120,
          dataIndex: "target_crossing",
          scopedSlots: { customRender: "target_crossing" },
        },
        {
          title: "操作",
          width: 80,
          dataIndex: "action",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],
    };
  },
  methods: {
    addItem() {
      this.dataItems.push({
        uid: new Date().getTime(),
        target_crossing: undefined,
        department: undefined,
        ursa_set: [],
        airport: undefined,
        destination_location: undefined,
        country: undefined,
        residential: undefined,
        postal_code: undefined,
        sensitive: undefined,
        packing_code: undefined,
      });
    },
    removeItem(index) {
      this.dataItems.splice(index, 1);
      this.handleChange();
    },
    handleChange() {
      this.$emit("change", this.dataItems);
    },
  },
  mounted() {
    if (this.value) {
      this.dataItems = this.value.map((item) => ({ uid: item.id, ...item })).sort((a, b) => a.id - b.id);
    } else {
      this.dataItems = [
        {
          uid: new Date().getTime(),
          target_crossing: undefined,
          department: undefined,
          ursa_set: [],
          airport: undefined,
          destination_location: undefined,
          country: undefined,
          residential: undefined,
          postal_code: undefined,
          sensitive: undefined,
          packing_code: undefined,
        },
      ];
    }
  },
};
</script>

<style scoped></style>
